import * as React from 'react';
import { graphql } from 'gatsby';
import {
	Backdrop,
	Box,
	CircularProgress,
	Grid,
} from '@mui/material';
import { navigate } from "gatsby";
import ExperimentCard from "../components/ExperimentCard"
import { db } from '../../firebase-info';
import {
	collection,
	getDocs,
	orderBy,
	query,
	where
} from "firebase/firestore";
import BeAlert from '../components/be-alert';
import {SIDEBAR_COLLAPSED_WIDTH} from "../components/SideBar"
import BeGeneralPage from "../components/be-general-page";
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { AuthContext } from '../contexts/contexts.js';

// markup
const MyExperiments = (props) => {
    const {t} = useTranslation();
	const authContextValue = React.useContext(AuthContext);
	const [experiment, setExperiment] = useLocalStorageState("experiment");
	const [experimentsOfResearcher, setExperimentsOfResearcher] = React.useState([]);
	const [experimentsLoading, setExperimentsLoading] = React.useState(true);
	const {state} = props.location || { success: false };

	async function loadExperimentsOfResearcher() {
		try {
			const role = authContextValue?.userData?.role;

			if (!role) {
				throw new Error('No role');
			}
			const experimentsQuery = await query(collection(db, 'experiments'), where("owner", "==", authContextValue.userRef), orderBy("lastModificationDate", "desc"));
			const experimentsSnapshot = await getDocs(experimentsQuery);
			
			setExperimentsOfResearcher(experimentsSnapshot.docs);
			setExperimentsLoading(false);
		} catch(e) {
			navigate("/");
			return;
		}
	};
  
	React.useEffect(() => {
		setExperiment(null);
		if (!authContextValue.loading) {
			loadExperimentsOfResearcher();
		}
	}, [authContextValue.loading]);

	return (
		<>
			{experimentsLoading ?
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={authContextValue.loading || experimentsLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			:
				<BeGeneralPage title={t("My experiments")}>
					{
						(state.success) ? (
						<BeAlert
							severity={"success"}
							message={t("Experiment created successfully")}
						/>
						) : null
					}
					<Grid container spacing={2} sx={{ overflow: 'auto' }} padding={4} mh={SIDEBAR_COLLAPSED_WIDTH}>
						{experimentsOfResearcher.map((elem, index) => {
							return (
								<Grid item xs={12} md={6} lg={4} key={`game-card-${index}`} pb={8}>
									<Box sx={{height: '270px'}}>
										<ExperimentCard experiment={elem.data()} canDownload={authContextValue.userData?.generalPermissions?.canDownload}/>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</BeGeneralPage>
			}
		</>
	);
};

export default MyExperiments;

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games","game_card","my_experiments","experiment_card","loading",]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
